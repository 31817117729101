import React from 'react';
import { render } from 'react-dom';
import * as auth from 'features/auth/auth';

auth.presetAuthWithCookie()
.finally(() => {
  // App should be loaded asyn, in order to delay store initialization.
  // This way, we can fill in local storage, and let application use it.
  import('./App').then(({ default: App }) => {
    render(
      <App />,
      document.getElementById('root')
    );
  });
});

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// import * as serviceWorker from './serviceWorker';
// serviceWorker.unregister();
