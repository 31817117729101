import axios from 'axios';

/**
 * Attach cookie to the document.
 * 
 * @param {string} key 
 * @param {string} value 
 * @param {{
 *          maxAge: ?string|number,
 *          expires: ?string
 *          domain: ?string,
 *          samesite: ?string,
 *          secure: ?boolean,
 *        }}
 */
export function setCookie(
  key,
  value,
  {
    maxAge = null,
    expires = null,
    domain = document.location.host.split('.').slice(-1. -1).join("."),
    samesite = 'lax',
    secure = process.env.NODE_ENV !== 'development',
  } = {}
) {
  let cookie = `${key}=${value}; domain=${domain}; samesite=${samesite}`;

  if (maxAge) {
    cookie = `${cookie}; max-age=${maxAge}`;
  }

  if (expires) {
    cookie = `${cookie}; expires=${expires}`;
  }

  if (secure) {
    cookie = `${cookie}; secure`;
  }

  document.cookie = cookie;
};

/**
 * Get all cookies attached to the document.
 * 
 * @returns {object} Object containing of key:value pairs of cookies.
 */
export function getCookies() {
  return Object.fromEntries(
    document.cookie.split(';').map(c => c.replace(/\s+/g, '').split('='))
  );
}

export function saveAuthCookie({ access_token, expires_in }) {
  const expires_at = (Date.now() / 1000 | 0) + expires_in;
  setCookie('token', access_token, expires_in);
  setCookie('token_expires', expires_at, expires_in);
};

export function removeAuthCookie() {
  setCookie('token', '', 1);
  setCookie('token_expires', '', 1);
}

export function parseAuthCookie() {
  const { token, token_expires } = getCookies();
  return { token, expires_at: token_expires };
};

export const presetAuthWithCookie = () => new Promise(resolve => {
  const { token, expires_at } = parseAuthCookie();

  if (!token) {
    return resolve();
  }

  if ((Date.now() / 1000 | 0) > parseInt(expires_at)) {
    return resolve();
  }

  const root = localStorage.getItem('persist:root');
  if (root) {
    const { userReducer } = JSON.parse(root);
    if (userReducer) {
      const { 
        loggingIn,
        userID,
      } = JSON.parse(userReducer);
      
      if (loggingIn && !!userID) {
        // In this case user is already logged in, so no need to
        // deley his access to the app.
        return resolve();
      }
    }
  }

  // If token is provided, emulate after auth state and let UI to load.
  axios.get('user', {
    baseURL: process.env.REACT_APP_API_ENTRYPOINT.replace(/\/$/, ''),
    timeout: 1000,
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(({ data: { data: { user } } }) => {
      // This is used by the http client of the rest of the app.
      localStorage.setItem('authToken', token);

      // Local storage contains user info, so that Redux may recognize
      // user as a logged one.
      const userRole = user.roles.find((role) => role.default).slug;
      const userReducer = {
        loggingIn: true,
        userRole: userRole,
        userID: user.uuid,
        status: user.status,
        userActive: !['inactive', 'banned', 'deleted'].includes(user.status),
        userName: user.name,
        userSurname: user.surname,
        userEmail: user.email,
        token: {
          access_token: token,
          expires_in: expires_at - (Date.now() / 1000 | 0),
          token_type: "bearer"
        },
        userData: user,
      };

      localStorage.setItem('persist:root', JSON.stringify({
        userReducer: JSON.stringify(userReducer),
      }));

      resolve();
    })
    .catch(() => {
      removeAuthCookie();
      resolve();
    });
});